import {useLayoutEffect, useEffect, useState } from 'react'

class DomPlayer {
  constructor(node) {
    this.node = node;
    this.playPromise = Promise.resolve();
    this.timePcent = 0;
  }

  setCurrent(key, src) {
    if (key === this.current()) {
      return;
    }
    this.timePcent = 0;
    this.node.setAttribute('key', key);
    this.node.src = src;
  }

  current() {
    return this.node.getAttribute('key');
  }

  isPlaying(key) {
    return this.current() === key && !this.node.paused
  }

  pause() {
    this.node.pause()
  }

  play(key, src) {
    this.pause();
    this.playPromise.then(
      () => {
        this.setCurrent(key, src);
        this.playPromise = this.node.play();
      }
    )
  }
}


export function usePlayer() {
  const [player, setPlayer] = useState(null);
  // force update
  const [, up] = useState(0);

  useLayoutEffect(() => {
    let d = document.querySelector('#player');
    if (!d) {
      d = document.createElement('audio');
      d.id = 'player';
      d.style.visibility = 'hidden';
      d.style.width = '0';
      d.style.height = '0';
      document.body.appendChild(d);
    }
    if (player) return;
    setPlayer(new DomPlayer(d));

  }, [player]);

  useEffect(() => {
    if (!player) return

    // because player updates does not mutate the object.
    const upd = _ => up(x => x+1)

    player.node.addEventListener('play', upd);
    player.node.addEventListener('pause', upd);
    return () => {
      if (!player) return;
      player.node.removeEventListener('play', upd);
      player.node.removeEventListener('pause', upd);
    }
  }, [player]);

  return player;
}

export function usePlayerTime(player) {
  const [time, setTime] = useState(player?.timePcent || 0);

  useEffect(() => {
    const reset = () => {
      setTime(0);
    }
    const utimeupdate = () => {
      const duration = player.node.duration;
      if (duration > 0) {
        player.timePcent = (player.node.currentTime / duration)
        setTime(player.timePcent);
      }
    };
    if (!player) return
    player.node.addEventListener('timeupdate', utimeupdate)
    player.node.addEventListener('loadstart', reset)

    return () => {
      player.node.removeEventListener('timeupdate', utimeupdate)
      player.node.removeEventListener('loadstart', reset)
    }
  }, [player]);

  return time;
}

