/*global THREE:false */

import rund from "./images/rund.png";

export default function runWebGL() {
  var container,
    camera,
    scene,
    renderer,
    particles,
    geometry,
    materials = [],
    parameters,
    i,
    mouseX = 0,
    mouseY = 0,
    windowHalfX = window.innerWidth / 2,
    windowHalfY = window.innerHeight / 2;

  initWebGL();
  animateWebGL();

  function initWebGL() {
    var size, vertex;
    container = document.createElement("div");
    container.id = "canvas";
    document.body.appendChild(container);

    camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      3000,
    );
    camera.position.z = -1200;

    scene = new THREE.Scene();
    scene.fog = new THREE.FogExp2(0x000000, 0.001);

    const vertices = [];
    for (i = 0; i < 2000; i++) {
      vertex = new THREE.Vector3();
      vertex.x = Math.random() * 2000 - 1000;
      vertex.y = Math.random() * 2000 - 1000;
      vertex.z = Math.random() * 2000 - 1000;
      vertices.push(vertex);
    }
    geometry = new THREE.BufferGeometry().setFromPoints(vertices);

    parameters = [
      // ["/images/AK47_colour.png", 5],
      // ["/images/ak47.png", 4],
      // ["/images/303.png", 9],
      // ["/images/wasser.png", 9],
      // ["/images/cake.gif", 4],
      // ["/images/cake2.gif", 5],
      // ["/images/cake3.gif", 5],

      // ["/images/star2.png", 15],
      // ["/images/milky.png", 8],
      ["/images/sagan.png", 5],
      [rund, 12],
      ["/images/tyfon.png", 10],
      ["/images/kompressor.png", 15],
      ["/images/mistlur.png", 10],

      ["/images/sagan.png", 5],
      ["/images/tyfon.png", 10],
      ["/images/kompressor.png", 15],
      ["/images/mistlur.png", 10],
    ];

    for (i = 0; i < parameters.length; i++) {
      size = parameters[i][1];

      materials[i] = new THREE.PointsMaterial({
        size: size,
        map: new THREE.TextureLoader().load(parameters[i][0]),
        transparent: true,
        blending: THREE.AdditiveBlending,
      });

      particles = new THREE.Points(geometry, materials[i]);

      particles.rotation.x = Math.random() * 6;
      particles.rotation.y = Math.random() * 6;
      particles.rotation.z = Math.random() * 6;

      scene.add(particles);
    }

    renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    container.appendChild(renderer.domElement);

    //stats = new Stats();
    //stats.domElement.style.position = 'absolute';
    //stats.domElement.style.top = '0px';
    //container.appendChild(stats.domElement);

    document.addEventListener("mousemove", onDocumentMouseMove, false);
    document.addEventListener("touchstart", onDocumentTouchStart, false);
    document.addEventListener("touchmove", onDocumentTouchMove, false);

    window.addEventListener("resize", onWindowResize, false);
  }

  function onWindowResize() {
    windowHalfX = window.innerWidth / 2;
    windowHalfY = window.innerHeight / 2;
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
  }

  function onDocumentMouseMove(event) {
    mouseX = 0.1 * event.clientX - windowHalfX;
    mouseY = 0.1 * event.clientY - windowHalfY;
  }

  function onDocumentTouchStart(event) {
    if (event.touches.length === 1) {
      mouseX = 0.1 * event.touches[0].pageX - windowHalfX;
      mouseY = 0.1 * event.touches[0].pageY - windowHalfY;
    }
  }

  function onDocumentTouchMove(event) {
    if (event.touches.length === 1) {
      mouseX = 0.1 * event.touches[0].pageX - windowHalfX;
      mouseY = 0.1 * event.touches[0].pageY - windowHalfY;
    }
  }

  function animateWebGL() {
    requestAnimationFrame(animateWebGL);
    render();
    //stats.update();
  }

  function render() {
    var time = Date.now() * 0.000002,
      object;

    camera.position.x += (mouseX - camera.position.x) * 0.05;
    camera.position.y += (-mouseY - camera.position.y) * 0.05;

    camera.lookAt(scene.position);

    for (i = 0; i < scene.children.length; i++) {
      object = scene.children[i];
      if (object instanceof THREE.Points) {
        object.rotation.y = time * (i < 2000 ? i + 2 : -(i + 2));
      }
    }

    renderer.render(scene, camera);
  }
}
