import { works } from "./audio";
import {IconPlay, IconPause} from "./icon"
import Content from "./CContent"
import {usePlayer, usePlayerTime} from "./usePlayer"

const duration = d => {
  const min = (d/60).toFixed(0);
  const sec = (d%60).toFixed(0);
  if (min === '0') {
    return `${sec}s`
  }
  if (sec === '0') {
    return `${min}m`
  }
  return `${min}m ${sec}s`
}

const Dur = ({t}) => <span style={{fontSize: '0.48em', color: '#999', whiteSpace: 'nowrap'}}>
  {duration(t)}
</span>;

const songKey = (w) => w.title+w.album
const currentSong = player => player && works.find(w => player.current() === songKey(w));

const songName = (work) => (work.album === 'untitled' ? "" : `${work.album}: `) + `${work.title}`

export default function PageAudio() {
  const player = usePlayer();
  const song = currentSong(player);
  const isPlaying = song && player.isPlaying(songKey(song))
  const timePcent = usePlayerTime(player);
  const handleSeek = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const clickX = e.clientX - rect.left;
    const duration = player.node.duration;
    player.node.currentTime = duration * (clickX / rect.width)
  }

  if(!player)  {
    return <></>;
  }

  return (
    <Content>
      <div style={{ margin: '0.5em', opacity: song ? 1 : 0.3, transition: 'opacity 0.35s ease-out' }}>
        {song ? (
          <>
            {isPlaying && <button className="playpause" onClick={_ => player.pause()}><IconPause /></button> }
            {!isPlaying && <button className="playpause" onClick={_ => player.play(songKey(song), song.src)}><IconPlay /></button>}
            {" "}
            {songName(song)} <Dur t={song.duration} />
            <div tabIndex="0" onClick={handleSeek} style={{width: 'auto', background: "var(--yellow)",  border: '5px solid #000', cursor: 'pointer'}} >
              <div style={{height: '1em', width: `${100 - timePcent * 100}%`, background: "rgba(0,0,0,0.5)", margin: '0 0 0 auto' }} />
            </div>
          </>
        ): (
          <>
            <button className="playpause" style={{opacity: 0}} ><IconPlay /></button>
            {" "}
            <div tabIndex="0" style={{width: 'auto', background: "var(--yellow)",  border: '5px solid #000', cursor: 'pointer'}} >
              <div style={{height: '1em', width: `${100 - timePcent * 100}%`, background: "rgba(0,0,0,0.5)", margin: '0 0 0 auto' }} />
            </div>
          </>
        )}
      </div>
      <div style={{ margin: '1.5em 0 0.5em 0'}}>
        {works.map((work, i) => (
          <div onClick={_ => player.play(songKey(work), work.src)} tabIndex="0" key={songKey(work)} style={{cursor: "pointer", padding: '0.125em 0.5em', background: `rgba(255,255,255,${i % 2 === 0 ? 0.09 : 0})`}}>
            {player.isPlaying(songKey(work)) ? (
              <span style={{fontStyle: "italic" }}>
                {">"} {songName(work)}  <Dur t={work.duration} />
              </span>
            ) : (
              <span style={{fontStyle: "italic" }}>
                {songName(work)} <Dur t={work.duration} />
              </span>
            )}
          </div>
        ))}
      </div>
    </Content>
  );
}
