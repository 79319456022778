import * as Icon from "./icon"

const ExtLink = ({ children, ...props }) =>  (
  <a
    style={{
      display: 'inline-block'
    }}
    target="_blank"
    rel="noreferrer noopener" {...props}>{children}</a>
)
export default function Footer() {
  return (
    <div className="footer">
      <div id="contact" className="footer-content" >
        <span>
          <b>Contact</b>
        </span>
        <ExtLink href="mailto:amina.hoc@gmail.com">
          <Icon.Email style={{width: '100%'}} />
        </ExtLink>
        <ExtLink href="https://www.instagram.com/aaaminaaaminaaaminaaa/">
          <Icon.Instagram  style={{width: '100%'}}  />
        </ExtLink>
        <span>
          <b>Links</b>
        </span>
        <ExtLink href="https://play.spotify.com/artist/6X41Zeam6qLK4tgAbIuzX4">
          <Icon.Spotify  style={{width: '100%'}}  />
        </ExtLink>
        <ExtLink href="https://aminahocine.bandcamp.com">
          <Icon.Bandcamp   style={{width: '100%'}} />
        </ExtLink>
      </div>
    </div>
  )
}
