import Content from "./CContent"
import AudioClip from "./CAudioClip"
import { titles } from  "./audio"

import imgMistlur1 from  "./images/mistlur1.jpg"
import imgMistlur2 from  "./images/mistlur2.jpg"

const ExtLink = ({ children, ...props }) =>  (
  <a
    style={{textDecoration: "none"}}
    target="_blank"
    rel="noreferrer noopener" {...props}>{children}</a>
)

export default function PageMistlur() {
  return (
    <Content className="text-small">
      <img src={imgMistlur2} alt="mistlursorgeln "/>
      <p>
        <AudioClip {...titles["MLO_1.1"]} /> My current work is surrounding an instrument I’ve developed during my
        studies in electro acoustic composition at The Royal College of Music in
        Stockholm and it is built out of PVC-pipes, various HVAC components and
        3D printed parts<sup><ExtLink href="https://github.com/benjaminwand/3d-printed-diaphone-pipe">1</ExtLink></sup>, and it is connected to an air compressor. The journey
        with the instrument started with a love and fascination for foghorns and
        signal horns but it has evolved to something much more. The Instrument
        is a way to spend time with airflow and to create synthesis without any
        computer or synthesizer. The pipes make a sound that can go from what
        sounds like a triangle wave to a sinus wave and a clear spectrum of
        harmonic overtones that change depending on where you stand in relation to
        the instrument. The work with The Instrument is continuous and ever
        evolving. Right now it exists in three versions.
      </p>
      <p>
        The big - is the original instrument, and consists of eight pipes distributed on two two meter tall wooden towers.
        <br/>The small - is a smaller version that consists of two one meter tall wooden towers with ten pipes distributed, tuned in mean tone temperament.
        <br/>ātamōn - is a deconstructed version of the instrument where the pipes are distributed around the audience.
      </p>
      <p>
        Music with The Instrument is planned to be released in spring 2024.
      </p>
      <img src={imgMistlur1} alt="mistlursorgeln"/>
    </Content>
  );
}
