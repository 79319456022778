import {IconPlay, IconPause} from "./icon"


export default function AudioClip({ player, src, title, album }) {
  const clipKey = title+album;
  if (!player) return <></>;

  return (
    <>
        {player.isPlaying(clipKey) && <button className="playpause" onClick={_ => player.pause()}><IconPause /></button> }
        {!player.isPlaying(clipKey) && <button className="playpause" onClick={_ => player.play(clipKey, src)}><IconPlay /></button>}
    </>
  );
}

