// Generated by ./gen_audio_info.js
// Do _not_ edit directly.
//
// Add or modify files in pubilc/audio and run
//
//    npm run genaud
//
export const works = [
  {
    "src": "/audio/MLO%201.1.mp3",
    "duration": "202.056000",
    "title": "MLO 1.1",
    "album": "Spelamistlur",
    "artist": "Amina Hocine",
    "date": "2022"
  },
  {
    "src": "/audio/batlat.mp3",
    "duration": "330.024000",
    "title": "Båtlåt",
    "album": "untitled",
    "artist": "Amina Hocine",
    "date": "2022"
  },
  {
    "src": "/audio/conflict_bada.mp3",
    "duration": "228.024000",
    "title": "Conflict is not abuse (båda)",
    "album": "untitled",
    "artist": "Amina Hocine",
    "date": "2022"
  },
  {
    "src": "/audio/nothing_unreal.mp3",
    "duration": "266.040000",
    "title": "Nothing unreal exists",
    "album": "untitled",
    "artist": "Amina Hocine",
    "date": "2022"
  }
]
export const titles = {"MLO_1.1":{"src":"/audio/MLO%201.1.mp3","duration":"202.056000","title":"MLO 1.1","album":"Spelamistlur","artist":"Amina Hocine","date":"2022"},"Båtlåt":{"src":"/audio/batlat.mp3","duration":"330.024000","title":"Båtlåt","album":"untitled","artist":"Amina Hocine","date":"2022"},"Conflict_is_not_abuse_(båda)":{"src":"/audio/conflict_bada.mp3","duration":"228.024000","title":"Conflict is not abuse (båda)","album":"untitled","artist":"Amina Hocine","date":"2022"},"Nothing_unreal_exists":{"src":"/audio/nothing_unreal.mp3","duration":"266.040000","title":"Nothing unreal exists","album":"untitled","artist":"Amina Hocine","date":"2022"}}
export const albums = {"Spelamistlur":[{"src":"/audio/MLO%201.1.mp3","duration":"202.056000","title":"MLO 1.1","album":"Spelamistlur","artist":"Amina Hocine","date":"2022"}],"untitled":[{"src":"/audio/batlat.mp3","duration":"330.024000","title":"Båtlåt","album":"untitled","artist":"Amina Hocine","date":"2022"},{"src":"/audio/conflict_bada.mp3","duration":"228.024000","title":"Conflict is not abuse (båda)","album":"untitled","artist":"Amina Hocine","date":"2022"},{"src":"/audio/nothing_unreal.mp3","duration":"266.040000","title":"Nothing unreal exists","album":"untitled","artist":"Amina Hocine","date":"2022"}]}
