import Content from "./CContent";

import photo from "./images/aminahocine_module.jpg";

const maybeLink = (g) => {
  if (g.URL) {
    return (
      <a href={g.URL} rel="noreferrer" target="_blank">
        {g.Title} {g.Location}
      </a>
    );
  }
  return (
    <>
      {g.Title} {g.Location}
    </>
  );
};

export default function PageAbout({ gigs }) {
  return (
    <Content>
      <h4>Upcoming Shows</h4>
      <div style={{ fontSize: "0.8em", marginBottom: "1.5em" }}>
        {gigs.map((g) => {
          return (
            <p key={g.Date} style={{ marginTop: 0 }}>
              <code style={{ fontSize: "0.8em" }}>{g.datef}: </code>
              {maybeLink(g)}
            </p>
          );
        })}
      </div>
      <div className="float-over text-small">
        Amina Hocine (b.1991) is Swedish composer, sound artist, and instrument
        creator based in Stockholm. She writes acousmatic, acoustic and notated
        music for musicians, focusing on longform pieces, exploring minimal
        harmonic movements and static rhythmic gestures. Her recent work has
        been centered around a self-made organ embodied from HVAC components,
        which produces mesmerizing drones with profound psychoacoustic effects.
        Drawing inspiration from spiritual sciences, she weaves detailed timbres
        which encourages deep listening in her compositions.
      </div>
      <img src={photo} className="mobile-thinner" alt="Amina" />
    </Content>
  );
}
