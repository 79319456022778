import Footer from "./CFooter"

export default function Content ({children, className=''}) {
  return (
    <>
      <div className="content-wrap">
        <div className={`content ${className}`}>
          {children}
        </div>
      </div>
      <Footer />
    </>
  )
}

