import "./App.css";
import { NavLink, Routes, Route } from "react-router-dom";
import PageAudio from "./PageAudio";
import PageMistlur from "./PageMistlur";
import PageAbout from "./PageAbout";
// import PageCV from "./PageCV";
import Papa from "papaparse";
import Footer from "./CFooter";
import { useEffect, useState } from "react";

function Page() {
  return (
    <>
      <div className="content-wrap"></div>
      <Footer />
    </>
  );
}

async function gigs() {
  const resp = await fetch(
    "https://docs.google.com/spreadsheets/d/e/2PACX-1vSFQDpX9nHfDtZjYFwgAj-HUI-GURnHHfMh_DxyqZ4f1yA6oRHOLczFqr0N8tiZr0b8DnEQsCeVmp6k/pub?gid=0&single=true&output=csv",
  );
  const body = await resp.text();
  const results = Papa.parse(body, { header: true });
  const thegigs = results.data
    .map((d) => {
      const parsedDate = new Date(d.Date);
      return {
        ...d,
        Date: isNaN(parsedDate.getDate()) ? d.Date : parsedDate,
      };
    })
    .filter((g) => g.Date >= new Date() - 24 * 60 * 60 * 1000)
    .sort((a, b) => {
      if (a.Date.constructor !== Date) {
        return 0;
      }
      if (b.Date.constructor !== Date) {
        return 0;
      }
      return a.Date - b.Date;
    });

  return (
    thegigs
      // .map((d) => ({
      //   ...d,
      //   Title: d.Title || "...",
      //   Location: d.Location || "...",
      // }))
      .map((d) => {
        const date = d.Date;
        if (date.constructor === String) {
          return {
            ...d,
            datef: d,
          };
        }
        if (!Intl) {
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0"); // getMonth() is zero-based
          const day = date.getDate().toString().padStart(2, "0");
          const datef = `${year}-${month}-${day}`;
          return {
            ...d,
            datef,
          };
        }
        const datef = new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        }).format(date);

        return {
          ...d,
          datef,
        };
      })
  );
}

function App() {
  const [thegigs, setGigs] = useState([]);
  useEffect(() => {
    (async () => {
      setGigs(await gigs());
    })();
  }, []);

  return (
    <div className="App">
      <NavLink className="main-link" to="/">
        Amina Hocine{" "}
      </NavLink>
      <nav>
        <NavLink to="/about">About</NavLink>
        <NavLink to="/mustlursorgeln">The Instrument</NavLink>
        <a href="/cv.pdf" download>
          CV
        </a>
        <NavLink to="/listen">Listen</NavLink>
      </nav>
      <Routes>
        <Route path="/" element={<Page />} />
        <Route path="/about" element={<PageAbout gigs={thegigs} />} />
        <Route path="/mustlursorgeln" element={<PageMistlur />} />
        <Route path="/listen" element={<PageAudio />} />
        {
          // <Route path="/works" element={<PageCV />} />
        }
      </Routes>
    </div>
  );
}

export default App;
